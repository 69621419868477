import React from "react"
import PropTypes from "prop-types"
import "./PostFooter.scss"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import Icon from "../Icon/Icon"

function PostFooter({ date, shareIntro }) {
  return (
    <div className="post-footer">
      <div className="post-footer__date">
        <p>
          <strong>Geplaatst:</strong> {date}
        </p>
      </div>
      <div className="post-footer__social">
        <p>
          <strong>Delen:</strong>
        </p>
        <ul className="post-footer__social--list">
          <li>
            <FacebookShareButton
              url={window.location}
              quote={shareIntro || ""}
              className="post-footer__social--item"
            >
              <Icon name="facebook" />
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={window.location}
              title={shareIntro || ""}
              className="post-footer__social--item"
            >
              <Icon name="twitter" />
            </TwitterShareButton>
          </li>
        </ul>
      </div>
    </div>
  )
}

PostFooter.propTypes = {
  date: PropTypes.string,
}

export default PostFooter
